// checkbox input field

.app-checkbox-input {
  display: none;
}

.app-checkbox-input-label {
  display: flex;

  font-family: CeraPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: var(--true-grey-700);
}

.app-checkbox-input-label::before {
  margin-right: 11px;
  width: 24px;
  height: 24px;
}

.app-checkbox-input + .app-checkbox-input-label::before {
  content: url("/assets/icons/checkbox-unchecked.svg");
}

.app-checkbox-input:checked + .app-checkbox-input-label::before {
  content: url("/assets/icons/checkbox-checked.svg");
}

// flat button

.app-flat-button {
  width: 100% !important;
  height: 48px;
  border-radius: 4px;
  border: none;
  background-color: var(--red-700)!important;

  font-family: CeraPro;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: var(--blanc);
  outline: none;
}

.app-flat-button:hover:enabled {
  background-image: linear-gradient(
    to bottom,
    #B71C1C -22%,
    var(--true-grey-900) 141%
  );
  cursor: pointer;
}

.app-flat-button:disabled {
  background-color: #E5E5E5;
 // color: var(--true-grey-400);
}

// stroked button

.app-stroked-button {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: solid 2px var(--red-700);
  background-color: var(--blanc);

  font-family: CeraPro;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: var(--red-700);
  outline: none;
}

.app-stroked-button:hover:enabled {
  background-image: linear-gradient(
    to bottom,
    #B71C1C -22%,
    var(--true-grey-900) 141%
  );
  color: var(--blanc);
  cursor: pointer;
}

.app-stroked-button:disabled {
  border: none;
  background-color: #E5E5E5;
  color: var(--true-grey-400);
}

// raised buttons
.app-raised-button,
.raised-btn {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(50, 130, 204, 0.6);
  background-color: var(--red-700)!important;
  font-family: CeraPro;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: var(--blanc);
}

.app-raised-button:hover:enabled {
  background-image: linear-gradient(
    to bottom,
    #B71C1C -22%,
    var(--true-grey-900) 141%
  );
  cursor: pointer;
}
