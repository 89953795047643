@font-face {
  font-family: CeraPro;
  src: local(CeraPro-Regular),
    url(/assets/fonts/CeraPro-Regular.woff) format("woff"),
    url(/assets/fonts/CeraPro-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: CeraPro;
  src: local(CeraPro-Medium),
    url(/assets/fonts/CeraPro-Medium.woff) format("woff"),
    url(/assets/fonts/CeraPro-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: CeraPro;
  src: local(CeraPro-Bold), url(/assets/fonts/CeraPro-Bold.woff) format("woff"),
    url(/assets/fonts/CeraPro-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: CeraPro;
  src: local(CeraPro-Black),
    url(/assets/fonts/CeraPro-Black.woff) format("woff"),
    url(/assets/fonts/CeraPro-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: CeraPro;
  src: local(CeraPro-Light),
    url(/assets/fonts/CeraPro-Light.woff) format("woff"),
    url(/assets/fonts/CeraPro-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
