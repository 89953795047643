:root {
  --green-700: #00C853;
  --true-grey-900: #18181B;
  --true-grey-400: #A8A29E;
  --blanc: #ffffff;
  --blanc-anti-flash-3: #4f4f51;
  --red-700: #D50000;
  --true-grey-700: #404040;
  --true-grey-200: #E5E5E5;
  --bleu-hawkes: #d7d8db;
  --bleu-pigment-2: #a9bbd2;
  --red-gradient: #B71C1C;
  --rouge-vermeil: #ff0a22;
  --true-grey-100: #F5F5F5;
  --orange-700: #FF6D00;
  --rouge-vermeil-1: #ab0613;
  --vert-jaunatre: #4a9d21;
  --vert-jaunatre-dark-shade: #2f2f2e;
  --vert-jaunatre-dark-shade-extreme: #1d1d1b;
  --gris-44: #707070;
  --gris-73: #bababa;
  --nobel: #a0a0a0;
  --alice-bleu: #f0f3f4;
  --blanc-fantome: #f8f8f9;
  --miellat: #f2f3f2;
  --bleu-klein: #013382;
  --very-light-shade-grey : #f6f6f6;
  --bleu-low-opacity: #3282cd33;
  --blanc-fummée: #f1f1f1;
  --bleu-magenta: #f2f2f4a8;
  --bleu-magenta-2: #f2f2f4a8;
  --cyan-shade: #00a5d8;
  --true-grey-400: #A8A29E;
  --mariner: #2178c2;
  --rouge-tutu: #fcdddd;
  --fyord: #4b5b62;
}
