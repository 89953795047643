// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
@import "./assets/scss/_palette.scss";

@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Tekkeys-primary: mat-palette($tekkeys-dark-color);
$Tekkeys-accent: mat-palette($tekkeys-primary-color, A200, A100, A400);

$Tekkeys-warn: mat-palette($tekkeys-warn-color);

// Create the theme object (a Sass map containing all of the palettes).
$Tekkeys-theme: mat-light-theme(
  $Tekkeys-primary,
  $Tekkeys-accent,
  $Tekkeys-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($Tekkeys-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: CeraPro, sans-serif;
  background-color: var(--true-grey-100);
}

* {
  box-sizing: border-box;
  font-family: CeraPro, sans-serif;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 8px !important;
  border: solid 1px var(--red-700);
  overflow: unset !important;
  box-shadow: 0 6px 26px 0 rgba(50, 130, 204, 0.2);
}

@import "./assets/scss/style.scss";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

// app start loading
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    flex-basis: 15%;
    min-width: 100px;
    animation-name: logo-scale;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }
}
/* styles.scss */
.mat-snack-bar-container.success-snackbar {
  background-color: #00C853 !important; /* Couleur verte */
  color: white !important; /* Texte en blanc */
  border-radius: 8px;
}


@keyframes logo-scale {
  0% {
    transform: scale(0.1);
  }
  50% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
  0% {
    transform: scale(0.1);
  }
}

// type of  snackbar

.error-snackbar {
  background-color: var(--rouge-vermeil);
  color: rgb(248, 248, 238);
  .mat-simple-snackbar-action {
    color: rgb(248, 248, 238);
  }
}



.information-snackbar {
  background-color: var(--red-700);
  color: rgb(248, 248, 238);
  .mat-simple-snackbar-action {
    color: rgb(248, 248, 238);
  }

}
@media print {
  app-navbar,
  app-footer,
  .no-print,
  mat-divider {
    display: none !important;
  }
  .print {
    display: block !important;
    transform: scale(0.5);
  }
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}
.price-input.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  background-color:#f5f5f5;
}
.mdc-notched-outline{
  display: none!important;
}
.go-to-page .mat-mdc-form-field-icon-suffix{
  margin-top: -14px;
}

